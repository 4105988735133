import * as i0 from '@angular/core';
import { Injectable, EventEmitter, inject, Component, Input, Output, HostListener, NgModule } from '@angular/core';
import { OKTA_AUTH } from '@okta/okta-angular';
import { Capacitor } from '@capacitor/core';
import { catchError, throwError, Subject, takeUntil } from 'rxjs';
import * as i1 from '@angular/common/http';
import { HttpHeaders, HttpClientModule } from '@angular/common/http';
import * as i2 from '@angular/platform-browser';
import * as i3 from '@angular/router';
import { CommonModule } from '@angular/common';
var LogLevel;
(function (LogLevel) {
  LogLevel["TRACE"] = "TRACE";
  LogLevel["DEBUG"] = "DEBUG";
  LogLevel["INFO"] = "INFO";
  LogLevel["WARN"] = "WARN";
  LogLevel["ERROR"] = "ERROR";
  LogLevel["FATAL"] = "FATAL";
  LogLevel["OFF"] = "OFF";
  LogLevel["ALL"] = "ALL";
})(LogLevel || (LogLevel = {}));
class AdiPlatformHeaderService {
  constructor(http) {
    this.http = http;
  }
  getHeaderHtmlContent(oktaToken, apiEndpoint, langCode, source) {
    const apiURL = apiEndpoint + '/auth/header';
    const accountNumber = localStorage.getItem('adi-selected-account-id');
    const headers = new HttpHeaders({
      Authorization: `Bearer ${oktaToken}`,
      Accept: 'text/html',
      'X-Adi-Source': source,
      'X-Adi-Trace-Id': localStorage.getItem('adi-trace-id')
    });
    return this.http.get(apiURL, {
      headers,
      responseType: 'text',
      params: {
        langCode,
        accountNumber
      }
    }).pipe(catchError(error => {
      return throwError(() => error);
    }));
  }
  loggingApiCall(apiEndpoint, logObj) {
    const apiURL = apiEndpoint + '/guest/logging';
    const headers = new HttpHeaders({
      'X-Adi-Trace-Id': localStorage.getItem('adi-trace-id')
    });
    return this.http.post(apiURL, logObj, {
      headers,
      responseType: 'text'
    }).pipe(catchError(error => {
      return throwError(() => error);
    }));
  }
  static {
    this.ɵfac = function AdiPlatformHeaderService_Factory(t) {
      return new (t || AdiPlatformHeaderService)(i0.ɵɵinject(i1.HttpClient));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: AdiPlatformHeaderService,
      factory: AdiPlatformHeaderService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AdiPlatformHeaderService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1.HttpClient
  }], null);
})();
class AdiHeaderComponent {
  constructor(headerService, _sanitizer, renderer, elementRef, cd, router) {
    this.headerService = headerService;
    this._sanitizer = _sanitizer;
    this.renderer = renderer;
    this.elementRef = elementRef;
    this.cd = cd;
    this.router = router;
    this.apiEndpoint = '';
    this.source = '';
    this.env = '';
    this.loader = new EventEmitter();
    this.oktaAuth = inject(OKTA_AUTH);
    this.unsubscribe$ = new Subject();
    this.htmlContent = this._sanitizer.bypassSecurityTrustHtml(this.staticHeaderContent());
  }
  onWindowScroll() {
    let headerMenu = document.getElementById('headerMenu');
    let menuIcon = document.querySelector('.menu-icon');
    if (headerMenu) {
      let computedStyle = window.getComputedStyle(headerMenu);
      let flexDirection = computedStyle.flexDirection;
      if (menuIcon && flexDirection === 'column') {
        headerMenu.style.display = 'none';
      }
    }
  }
  onClick(event) {
    let headerMenu = document.getElementById('headerMenu');
    let menuIcon = document.querySelector('.menu-icon');
    if (headerMenu) {
      let computedStyle = window.getComputedStyle(headerMenu);
      let flexDirection = computedStyle.flexDirection;
      if (flexDirection === 'column') {
        if (event.target !== menuIcon) {
          headerMenu.style.display = 'none';
          menuIcon.classList.remove('active');
        }
      }
    }
  }
  onResize() {
    let headerMenu = document.getElementById('headerMenu');
    let menuIcon = document.querySelector('.menu-icon');
    if (headerMenu) {
      let active = menuIcon.classList.contains('active');
      let computedStyle = window.getComputedStyle(headerMenu);
      let flexDirection = computedStyle.flexDirection;
      if (flexDirection === 'row') {
        headerMenu.style.display = 'flex';
      }
      if (flexDirection === 'column' && !active) {
        headerMenu.style.display = 'none';
      }
    }
  }
  clickOutside(event) {
    const target = event.target;
    const profileMenu = document.getElementById('myDropdown');
    const isSvgElement = target instanceof SVGElement || false;
    let profileIconClicked = false;
    if (isSvgElement) {
      profileIconClicked = true;
    }
    const clickedInside = profileMenu.contains(target) || profileIconClicked;
    if (!clickedInside) {
      profileMenu.classList.remove('show');
      this.cd.detectChanges();
    }
  }
  ngOnInit() {
    this.headerService.getHeaderHtmlContent(this.oktaAuth.getAccessToken(), this.apiEndpoint, localStorage.getItem('adi-selected-locale'), this.source).pipe(takeUntil(this.unsubscribe$)).subscribe({
      next: res => {
        this.htmlContent = this._sanitizer.bypassSecurityTrustHtml(res);
        this.cd.detectChanges();
        this.createEventListeners();
        this.createEventListenersForAuthorized();
      },
      error: err => {
        if (err.status === 401) {
          this.emitLoader(true);
          this.emitLogout();
        }
        this.htmlContent = this._sanitizer.bypassSecurityTrustHtml(this.staticHeaderContent());
        this.createEventListeners();
      }
    });
  }
  staticHeaderContent() {
    return `<style>
        #adiPlatformHeader {
            display: flex;
            justify-content: space-between;
            align-items: center;
            background: #003595;
            padding: 4px 48px;
            height: 64px;
        }

        /* Styles for the logo */
        #adiPlatformHeader .logo {
            display: flex;
            align-items: center;
        }
        
        /* Styles for the profile icon */
        #adiPlatformHeader .accountButton {
            display: flex;
            flex: auto;
            justify-content: flex-end;
        }

        #adiPlatformHeader .accountButton .profileIcon {
            cursor: pointer;
            position: relative;
        }

        #adiPlatformHeader .accountButton img {
            width: 72px;
            height: 64px;
        }

        #adiPlatformHeader .accountButton .profileIcon .dropbtn {
            background-color: transparent;
            color: white;
            margin-top: 5px;
            font-size: 16px;
            border: none;
            cursor: pointer;
        }

        /* The container <div> - needed to position the dropdown content */
        #adiPlatformHeader .accountButton .profileIcon .dropdown {
            position: relative;
            display: inline-block;
        }

        /* Dropdown Content (Hidden by Default) */
        #adiPlatformHeader .accountButton .profileIcon .dropdown .dropdown-content {
            display: none;
            position: absolute;
            min-width: 160px;
            z-index: 1;
        }

        /* Links inside the dropdown */
        #adiPlatformHeader .accountButton .profileIcon .dropdown .dropdown-content a {
            color: black;
            text-decoration: none;
            display: flex;
            align-items: center;
        }

        /* Change color of dropdown links on hover */
        #adiPlatformHeader .accountButton .profileIcon .dropdown .dropdown-content a:hover {
            border-radius: 12px;
        }

        #adiPlatformHeader .accountButton .profileIcon .dropdown .dropdown-content a svg {
            margin-right: 10px;
        }

        #adiPlatformHeader .accountButton .profileIcon .dropdown .dropdown-content a span {
            color: var(--Primary-600, var(--Primary-ace-color-primary-600, #003595));
            font-size: var(--Font-Size-S, 14px);
            font-style: normal;
            font-weight: 400;
            line-height: var(--Line-Height-Body-M, 19.6px);
        }

        .show {
            display: flex !important;
            right: 5px;
            top: 35px;
            padding: 20px;
            flex-direction: column;
            align-items: flex-start;
            gap: 16px;
            width: 193px;
            border-radius: 12px;

            background: var(--Neutral-ace-color-neutral-100, #fff);
            /* Elevation/Blue/200 */
            box-shadow: 0px 5px 20px 0px rgba(0, 53, 149, 0.15);
        }

        @media screen and (min-width: 1441px) {
            #adiPlatformHeader {
                padding: 5px calc((100vw - 1440px) / 2 + 48px);
            }
        }
    </style>
    <div id="adiPlatformHeader">
        <div class="logo">
            <img src="https://alcon.widen.net/content/pmxiwthkgx/webp/Adi_headerlogo.webp?position=c&quality=80&u=uxrrnh&use=ibhcw"
                alt="Adi Online Store">
        </div>
        <div class="accountButton">
            <div class="profileIcon">
                <div class="dropdown">
                    <button class="dropbtn">
                        <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="18"
                                height="22"
                                viewBox="0 0 18 22"
                                fill="none"
                        >
                            <path
                                    d="M17.75 20.5455C17.75 21.0731 17.3152 21.5 16.7778 21.5C16.2404 21.5 15.8056 21.0731 15.8056 20.5455C15.8056 16.8559 12.7579 13.8636 9 13.8636C5.24212 13.8636 2.19444 16.8559 2.19444 20.5455C2.19444 21.0731 1.7596 21.5 1.22222 21.5C0.684841 21.5 0.25 21.0731 0.25 20.5455C0.25 15.8007 4.16738 11.9545 9 11.9545C13.8326 11.9545 17.75 15.8007 17.75 20.5455ZM9 11C6.04726 11 3.65278 8.64905 3.65278 5.75C3.65278 2.85095 6.04726 0.5 9 0.5C11.9527 0.5 14.3472 2.85095 14.3472 5.75C14.3472 8.64905 11.9527 11 9 11ZM9 9.09091C10.8799 9.09091 12.4028 7.59571 12.4028 5.75C12.4028 3.90429 10.8799 2.40909 9 2.40909C7.12011 2.40909 5.59722 3.90429 5.59722 5.75C5.59722 7.59571 7.12011 9.09091 9 9.09091Z"
                                    fill="white"
                            />
                        </svg>
                    </button>
                    <div id="myDropdown" class="dropdown-content">
                        <a class="logoutButton" href="javascript:void(0)">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <mask id="mask0_8895_43993" style="mask-type: alpha" maskUnits="userSpaceOnUse" x="0" y="0"
                                      width="20" height="20">
                                    <rect width="20" height="20" fill="#D9D9D9" />
                                </mask>
                                <g mask="url(#mask0_8895_43993)">
                                    <path
                                            d="M11.6668 15L10.5002 13.7917L13.4585 10.8333H3.3335V9.16667H13.4585L10.5002 6.20833L11.6668 5L16.6668 10L11.6668 15Z"
                                            fill="#003595" />
                                </g>
                            </svg>
                            <span>Log out</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>`;
  }
  createEventListenersForAuthorized() {
    setTimeout(() => {
      const menuToggle = document.querySelector('.menu-toggle');
      if (menuToggle) {
        this.renderer.listen(document.querySelector('.menu-toggle'), 'click', () => {
          this.toggleModulesDropdown();
          this.cd.detectChanges();
        });
        this.renderer.listen(document.querySelector('.nav-cross'), 'click', () => {
          this.toggleModulesDropdown();
          this.cd.detectChanges();
        });
      }
      const appLinks = this.elementRef.nativeElement.querySelectorAll('.app-link');
      appLinks.forEach(link => {
        this.renderer.listen(link, 'click', event => {
          this.handleClick(event);
        });
      });
    }, 1000);
  }
  handleClick(event) {
    // Prevent default anchor tag behavior
    event.preventDefault();
    // Get href attribute of the clicked anchor tag
    const targetElement = event.target;
    let href = targetElement.getAttribute('href');
    const target = targetElement.getAttribute('target');
    this.emitLoader(true);
    const logObj = {
      eventType: 'Navigation',
      eventDetail: `Navigating the user to ${href}`,
      logLevel: LogLevel.INFO,
      applicationName: this.source
    };
    this.headerService.loggingApiCall(this.apiEndpoint, logObj).pipe(takeUntil(this.unsubscribe$)).subscribe({
      next: res => {
        this.emitLoader(false);
        this.navigateToLink(href, target);
      },
      error: err => {
        if (err.status === 401) {
          this.emitLoader(true);
          this.emitLogout();
        }
        this.emitLoader(false);
        this.navigateToLink(href, target);
      }
    });
  }
  navigateToLink(href, target) {
    localStorage.setItem('adi-sister-module-source', window.location.pathname);
    // Below condition is to open https even in native instead
    if (href && href !== '' && href !== '#') {
      if (Capacitor.isNativePlatform()) {
        //Below condition is to open href as is in case it starts with https (for ex - in case of online store)
        if (href.startsWith('https')) {
          window.open(href, '_self');
        } else {
          const mobileHref = `${window.location.origin}${href}`;
          const mobileHrefParts = mobileHref.split(':');
          mobileHrefParts[0] = 'https';
          const newMobilehref = mobileHrefParts.join(':');
          window.open(newMobilehref, '_self');
        }
      } else {
        window.open(href, target);
      }
    }
  }
  toggleModulesDropdown() {
    let headerMenu = document.getElementById('headerMenu');
    let menuIcon = document.querySelector('.menu-icon');
    if (headerMenu.style.display === 'flex') {
      headerMenu.style.display = 'none';
      menuIcon.classList.remove('active');
    } else {
      headerMenu.style.display = 'flex';
      menuIcon.classList.add('active');
    }
  }
  createEventListeners() {
    setTimeout(() => {
      // Listen for click on .logoutButton
      this.renderer.listen(document.querySelector('.logoutButton'), 'click', () => {
        this.emitLoader(true);
        this.emitLogout();
        this.cd.detectChanges();
      });
      // Listen for click on profileMenuButton
      this.renderer.listen(document.querySelector('.profileIcon'), 'click', () => {
        document?.getElementById('myDropdown')?.classList.toggle('show');
      });
    }, 1000);
  }
  toggleSettings() {
    const accountOptionsContainer = document.querySelector('.accountOptions');
    accountOptionsContainer?.classList.toggle('show');
  }
  //communication with app
  emitLogout() {
    //API CALL
    const logObj = {
      eventType: 'Log Out',
      eventDetail: 'User has triggered logout',
      logLevel: LogLevel.INFO,
      applicationName: this.source
    };
    this.headerService.loggingApiCall(this.apiEndpoint, logObj).pipe(takeUntil(this.unsubscribe$)).subscribe({
      next: res => {
        this.navigateToLogout();
      },
      error: err => {
        this.navigateToLogout();
      }
    });
  }
  navigateToLogout() {
    window.location.href = `${window.location.origin}/${localStorage.getItem('adi-selected-locale')}/logout`;
  }
  emitLoader(val) {
    this.loader.emit(val);
  }
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
  static {
    this.ɵfac = function AdiHeaderComponent_Factory(t) {
      return new (t || AdiHeaderComponent)(i0.ɵɵdirectiveInject(AdiPlatformHeaderService), i0.ɵɵdirectiveInject(i2.DomSanitizer), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i3.Router));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: AdiHeaderComponent,
      selectors: [["adi-header-lib"]],
      hostBindings: function AdiHeaderComponent_HostBindings(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵlistener("scroll", function AdiHeaderComponent_scroll_HostBindingHandler() {
            return ctx.onWindowScroll();
          }, false, i0.ɵɵresolveWindow)("click", function AdiHeaderComponent_click_HostBindingHandler($event) {
            return ctx.clickOutside($event);
          }, false, i0.ɵɵresolveDocument)("resize", function AdiHeaderComponent_resize_HostBindingHandler($event) {
            return ctx.onResize($event);
          }, false, i0.ɵɵresolveWindow);
        }
      },
      inputs: {
        apiEndpoint: "apiEndpoint",
        source: "source",
        env: "env"
      },
      outputs: {
        loader: "loader"
      },
      decls: 1,
      vars: 1,
      consts: [[3, "innerHTML"]],
      template: function AdiHeaderComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelement(0, "div", 0);
        }
        if (rf & 2) {
          i0.ɵɵproperty("innerHTML", ctx.htmlContent, i0.ɵɵsanitizeHtml);
        }
      }
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AdiHeaderComponent, [{
    type: Component,
    args: [{
      selector: 'adi-header-lib',
      template: `<div [innerHTML]="htmlContent"></div>`
    }]
  }], () => [{
    type: AdiPlatformHeaderService
  }, {
    type: i2.DomSanitizer
  }, {
    type: i0.Renderer2
  }, {
    type: i0.ElementRef
  }, {
    type: i0.ChangeDetectorRef
  }, {
    type: i3.Router
  }], {
    apiEndpoint: [{
      type: Input,
      args: ['apiEndpoint']
    }],
    source: [{
      type: Input,
      args: ['source']
    }],
    env: [{
      type: Input,
      args: ['env']
    }],
    loader: [{
      type: Output,
      args: ['loader']
    }],
    onWindowScroll: [{
      type: HostListener,
      args: ['window:scroll', []]
    }],
    onClick: [{
      type: HostListener,
      args: ['document:click', ['$event']]
    }],
    onResize: [{
      type: HostListener,
      args: ['window:resize', ['$event']]
    }],
    clickOutside: [{
      type: HostListener,
      args: ['document:click', ['$event']]
    }]
  });
})();
class AdiHeaderModule {
  static {
    this.ɵfac = function AdiHeaderModule_Factory(t) {
      return new (t || AdiHeaderModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: AdiHeaderModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      providers: [AdiPlatformHeaderService],
      imports: [CommonModule, HttpClientModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AdiHeaderModule, [{
    type: NgModule,
    args: [{
      declarations: [AdiHeaderComponent],
      imports: [CommonModule, HttpClientModule],
      exports: [AdiHeaderComponent],
      providers: [AdiPlatformHeaderService]
    }]
  }], null, null);
})();

/*
 * Public API Surface of header
 */

/**
 * Generated bundle index. Do not edit.
 */

export { AdiHeaderComponent, AdiHeaderModule };
