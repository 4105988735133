import * as i1 from '@angular/common/http';
import { HttpHeaders, HttpClientModule } from '@angular/common/http';
import * as i0 from '@angular/core';
import { Injectable, inject, Component, Input, NgModule } from '@angular/core';
import { OKTA_AUTH } from '@okta/okta-angular';
import { Subject, takeUntil } from 'rxjs';
import * as i1$1 from '@angular/platform-browser';
import * as i3 from '@angular/router';
import { CommonModule } from '@angular/common';
class AdiPlatformFooterService {
  constructor(http) {
    this.http = http;
  }
  getFooterHtmlContent(oktaToken, apiEndpoint, langCode, source) {
    const apiURL = apiEndpoint + '/auth/footer';
    const headers = new HttpHeaders({
      Authorization: `Bearer ${oktaToken}`,
      Accept: 'text/html',
      'X-Adi-Source': source,
      'X-Adi-Trace-Id': localStorage.getItem('adi-trace-id')
    });
    return this.http.get(apiURL, {
      headers,
      responseType: 'text',
      params: {
        langCode
      }
    });
  }
  static {
    this.ɵfac = function AdiPlatformFooterService_Factory(t) {
      return new (t || AdiPlatformFooterService)(i0.ɵɵinject(i1.HttpClient));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: AdiPlatformFooterService,
      factory: AdiPlatformFooterService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AdiPlatformFooterService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1.HttpClient
  }], null);
})();
class AdiPlatformFooterComponent {
  constructor(_sanitizer, footerService, router, renderer, cd) {
    this._sanitizer = _sanitizer;
    this.footerService = footerService;
    this.router = router;
    this.renderer = renderer;
    this.cd = cd;
    this.apiEndpoint = '';
    this.source = '';
    this.oktaAuth = inject(OKTA_AUTH);
    this.unsubscribe$ = new Subject();
    this.htmlContent = this._sanitizer.bypassSecurityTrustHtml(this.createDummyHtml());
  }
  ngOnInit() {
    this.footerService.getFooterHtmlContent(this.oktaAuth.getAccessToken(), this.apiEndpoint, localStorage.getItem('adi-selected-locale'), this.source).pipe(takeUntil(this.unsubscribe$)).subscribe(res => {
      this.htmlContent = this._sanitizer.bypassSecurityTrustHtml(res);
      this.cd.detectChanges();
      this.createEventListeners();
    });
  }
  createEventListeners() {
    setTimeout(() => {
      // Getting elements for language dropdown desktop/tablet
      const languageDropdownDesktop = document.getElementById('languageDropdown_desktop');
      // Getting elements for language dropdown mobile
      const languageDropdownMobile = document.getElementById('languageDropdown_mobile');
      // Getting elements for language dropdown options
      const languageDropdownOption = document.querySelectorAll('.language-option');
      // Open and close language dropdown - Mobile
      if (languageDropdownMobile) {
        this.renderer.listen(languageDropdownMobile, 'click', () => {
          document.getElementById('languageDropdownContent_mobile')?.classList.toggle('hide');
          document.getElementById('languageDropdownContent_mobile')?.classList.toggle('addFlex');
        });
        this.cd.detectChanges();
      }
      // Open and close language dropdown - Desktop/Tablet
      if (languageDropdownDesktop) {
        this.renderer.listen(languageDropdownDesktop, 'click', () => {
          document.getElementById('languageDropdownContent_desktop')?.classList.toggle('hide');
          document.getElementById('languageDropdownContent_desktop')?.classList.toggle('addFlex');
        });
        this.cd.detectChanges();
      }
      // Select language option
      if (languageDropdownOption) {
        languageDropdownOption?.forEach(options => {
          this.renderer.listen(options, 'click', value => {
            const dropdownValue = value?.srcElement?.value;
            this.selectLanguage(dropdownValue);
          });
        });
        this.cd.detectChanges();
      }
    }, 1000);
  }
  selectLanguage(language) {
    if (localStorage.getItem('adi-selected-locale') !== language) {
      this.appendLanguageToUrl(language);
    }
  }
  changeLanguageFromSelector(languageSelectorElement) {
    const selectedLangValue = languageSelectorElement.value;
    if (localStorage.getItem('adi-selected-locale') !== selectedLangValue) {
      this.appendLanguageToUrl(selectedLangValue);
    }
  }
  changeLocaleOnPageLoad() {
    const thisRef = this;
    setTimeout(() => {
      const selectedLocale = document.getElementById('selectedLocale').value;
      const tempSelectedVal = selectedLocale;
      if (localStorage.getItem('adi-selected-locale') !== tempSelectedVal) {
        thisRef.appendLanguageToUrl(selectedLocale);
      }
    }, 1000);
  }
  appendLanguageToUrl(language) {
    const currentUrl = this.router.url;
    const segments = currentUrl.split('/');
    if (segments.length >= 2) {
      if (this.source === 'store') {
        segments[3] = language;
      } else if (this.source === 'clinicconnect') {
        segments[2] = language;
      } else {
        segments[1] = language;
      }
      const newUrl = segments.join('/');
      localStorage.setItem('adi-selected-locale', language);
      window.location.href = `${window.location.origin}${newUrl}`;
    }
  }
  createDummyHtml() {
    return `<style>
        #adiPlatformFooter {
            width: 100%;
            height: 192px;
            padding: 48px;
            background-color: #003595;
            padding: 48px;
            display: flex;
            flex-direction: column; 
            justify-content: flex-end;
            align-items: flex-end;
            position: relative;
        }
        @media screen and (min-width: 1441px) {
                #adiPlatformFooter {
                    padding: 48px calc((100vw - 1440px) / 2 + 48px);
                }
            }
    </style>
    <div id="adiPlatformFooter">
        <img src="https://alcon.widen.net/content/zw1zquqdz4/png/Adi%20Logo_Footer_Optimized.png?crop=false&position=c&u=uxrrnh&w=96&h=48" alt="Adi Home Logo" class="logo">
    </div>`;
  }
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
  static {
    this.ɵfac = function AdiPlatformFooterComponent_Factory(t) {
      return new (t || AdiPlatformFooterComponent)(i0.ɵɵdirectiveInject(i1$1.DomSanitizer), i0.ɵɵdirectiveInject(AdiPlatformFooterService), i0.ɵɵdirectiveInject(i3.Router), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: AdiPlatformFooterComponent,
      selectors: [["adi-footer-lib"]],
      inputs: {
        apiEndpoint: "apiEndpoint",
        source: "source"
      },
      decls: 1,
      vars: 1,
      consts: [[3, "innerHTML"]],
      template: function AdiPlatformFooterComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelement(0, "div", 0);
        }
        if (rf & 2) {
          i0.ɵɵproperty("innerHTML", ctx.htmlContent, i0.ɵɵsanitizeHtml);
        }
      }
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AdiPlatformFooterComponent, [{
    type: Component,
    args: [{
      selector: 'adi-footer-lib',
      template: `<div [innerHTML]="htmlContent"></div>`
    }]
  }], () => [{
    type: i1$1.DomSanitizer
  }, {
    type: AdiPlatformFooterService
  }, {
    type: i3.Router
  }, {
    type: i0.Renderer2
  }, {
    type: i0.ChangeDetectorRef
  }], {
    apiEndpoint: [{
      type: Input,
      args: ['apiEndpoint']
    }],
    source: [{
      type: Input,
      args: ['source']
    }]
  });
})();
class AdiFooterModule {
  static {
    this.ɵfac = function AdiFooterModule_Factory(t) {
      return new (t || AdiFooterModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: AdiFooterModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      providers: [AdiPlatformFooterService],
      imports: [CommonModule, HttpClientModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AdiFooterModule, [{
    type: NgModule,
    args: [{
      declarations: [AdiPlatformFooterComponent],
      imports: [CommonModule, HttpClientModule],
      exports: [AdiPlatformFooterComponent],
      providers: [AdiPlatformFooterService]
    }]
  }], null, null);
})();

/*
 * Public API Surface of footer
 */

/**
 * Generated bundle index. Do not edit.
 */

export { AdiFooterModule, AdiPlatformFooterComponent, AdiPlatformFooterService };
